<template>
  <div>
    <h1>KEEP</h1>
    <div v-if="(rule != null) && rule.hasCompulsories">
      <div class="intro" v-if="rule.numberOfCompulsories == 1">Choisissez la figure imposée pour cette catégorie :</div>
      <div class="intro" v-else>Choisissez les {{ rule.numberOfCompulsories }} figures imposées pour cette catégorie (<b>{{ selected.length }} / {{ rule.numberOfCompulsories }}</b>):</div>
        <div class="leftPaneContainer">
          <div v-for="compulsory in compulsories" :key="compulsory.id" >
            <div class="compulsory">
              <b-checkbox
                size="xl"
                :value="compulsory.id"
                v-model="bSelected"
                @change.native="selectCompulsory(compulsory.id)"
              >
                <b-badge pill variant="info">{{ compulsorySelectionOrder(compulsory) }}</b-badge>
                {{ compulsory.code }} - {{ compulsory.name }}
              </b-checkbox>
            </div>
          </div>
        </div>
        <div class="rightPaneContainer">
            <img v-if="false" :src='`/Compulsories/${selectedCompulsoryCode}.JPG`' />
        </div>
    </div>
  </div>
</template>

<script>
import {RST_COMPETITION, RST_ROUND, RST_LEVEL, SEASON_RULE, SEASON_COMPULSORY, REF_COMPULSORY, RST_ROUND_COMPULSORY } from "@/LocalDB";
import SynchroHelper from "@/services/helpers/synchroHelper";
import { v4 as uuid} from 'uuid';

export default {
  props: {
    category: { type: Object, required: false}, // Type attendu RST_CATEGORY
  },
  data(){
    return {
      bSelected: [],
      selectedCompulsoryId: ''
    }
  },
  computed:{
    selectedCompulsoryCode(){
      if(this.selectedCompulsoryId == null || this.selectedCompulsorId == '')
        return null;
      //return REF_COMPULSORY.query().where("id", this.selectedCompulsoryId).first().code
      return null;
    },
    competition(){
      var ret = RST_COMPETITION.find(this.category.competition_id);
      return ret;
    },
    round(){
      var ret = RST_ROUND.query()
                  .where("competition_id", this.category.competition_id)
                  .where("round_number", this.category.round_number)
                  .first();
      return ret;
    },
    level(){
      var ret = RST_LEVEL.query()
                  .where("competition_id", this.category.competition_id)
                  .where("round_number", this.category.round_number)
                  .where("level", this.category.level)
                  .first();
      return ret;
    },
    compulsories(){
      var ret = SEASON_COMPULSORY.query()
                  .where("year", this.competition.year)
                  .get()
                  .map(item => REF_COMPULSORY.find(item.compulsory_id))
                  .filter(item => item.level == this.category.level && item.category == this.category.category);
      return ret;
    },
    selected(){
      var ret = RST_ROUND_COMPULSORY.query()
                  .where("competition_id", this.category.competition_id)
                  .where("round_number", this.category.round_number)
                  .where("level", this.category.level)
                  .where("category", this.category.category)
                  .get();
      return ret;
    },
    rule(){
      var categoryCode = this.category.rule;
      var levelCode = this.level.rule;
      var roundCode = this.round.rule;

      var code = (categoryCode != null)
               ? categoryCode
               : ((levelCode != null) ? levelCode : roundCode);
       var ret = SEASON_RULE.query()
                  .where("competition_type", this.competition.competition_type)
                  .where("year", this.competition.year)
                  .where('level', this.level.level)
                  .where('rule', code)
                  .first();
      return ret;
    },
  },
  methods:{
    getImgUrl(code){
//      return require( `@/assets/Compulsories/${code}.JPG`);
      return `/Compulsories/${code}.JPG`;
    },
    compulsorySelectionOrder(compulsory){
      var found = this.selected.filter(item => item.compulsory_id == compulsory.id)[0];
      if(found != null)
        return found.order;
      return '';
    },
    selectCompulsory(compulsory){
      this.$showRefresher()
        .then(() => {
          var found = (this.bSelected.findIndex(item => item == compulsory) >= 0);
          if(!found)
          {
            // On est dans une situation de suppression de l'une des figures préalablement sélectionnées
            // On va donc parcourir toutes les figures choisies afin de supprimer celle qu'il faut et ré-indicer les autres
            var existings = RST_ROUND_COMPULSORY.query()
                            .where(item => { return item.competition_id == this.competition.id
                                            && item.round_number == this.round.round_number
                                            && item.level == this.level.level
                                            && item.category == this.category.category
                                            })
                            .orderBy("order")
                            .get();

            console.log('bSelected', this.bSelected);
            existings.forEach(existing => {
              var corrIndex = this.bSelected.findIndex(item => item == existing.compulsory_id);
              if(corrIndex < 0)
              {
                // Suppression de la figure
                RST_ROUND_COMPULSORY.delete(existing.id);
                SynchroHelper.LogOperation("DELETE", "RST_ROUND_COMPULSORY", existing.id, null);
                this.selectedCompulsoryId = '';
              }
              else
              {
                // on modifie l'indice de la figure
                RST_ROUND_COMPULSORY.update({where: existing.id, data:{ order: corrIndex + 1} });
                SynchroHelper.LogOperation("UPDATE", "RST_ROUND_COMPULSORY", existing.id, { order: corrIndex + 1 } , "RE-ORDER");
                this.selectedCompulsoryId = compulsory;
              }
            });
          } else {
            // On est dans une situation d'ajout d'une figure choisie
            var index = this.bSelected.findIndex(item => item == compulsory);
            var insertData = {
                id: uuid(),
                competition_id: this.competition.id,
                round_number: this.round.round_number,
                level: this.level.level,
                category: this.category.category,
                order: index + 1,
                compulsory_id : compulsory,
            };
            RST_ROUND_COMPULSORY.insertOrUpdate({ data: insertData });
            SynchroHelper.LogOperation ("INSERT", "RST_ROUND_COMPULSORY", null, insertData);
                this.selectedCompulsoryId = compulsory;
          }
          SynchroHelper.TryToSynchronize();
          this.$hideRefresher();
      });
    },
    refresh(){
      this.bSelected = [];
      RST_ROUND_COMPULSORY.query()
            .where("competition_id", this.category.competition_id)
            .where("round_number", this.category.round_number)
            .where("level", this.category.level)
            .where("category", this.category.category)
            .get()
            .forEach(item => {
              this.bSelected.push(item.compulsory_id);
            });
    }
  },
  mounted(){
    this.refresh();
  }
}
</script>

<style scoped>
  .intro{ margin-top: 15px; margin-bottom: 10px; }
</style>
